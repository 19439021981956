import React, { useState } from "react";
import ChangeStylistModal from "./ChangeStylistModal";
import axios from "axios";
import AssignStylist from "./AssignStylist";
import { getProvidersByZipcodeAndService } from "services/provider.service";

const ChangeStylist = ({
  activeOrder,
  selectedRow,
  getallOrders,
  getOrderById,
  toggleModel,
  isHotel,
  providerId,
  pastPendingTab,
}) => {
  console.log(pastPendingTab, "pastPendingTab");
  const [modal, setModal] = useState(false);
  const [newProviders, setNewProviders] = useState([]);
  const [newProviderOptions, setNewProviderOptions] = useState([]);
  // console.log(newProviders, "newproviders", newProviderOptions,selectedRow);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const getProvidersByZipCode = async activeOrder => {
    console.log(activeOrder, "actorder");
    // debugger
    // let selectedServices = activeOrder?.selectedList;
    // if (selectedRow) {
    // let tempData = [...selectedServices];
    // tempData = tempData?.map(item => ({
    //   _id: item?.serviceid?._id,
    //   selectedServiceOptions: item?.selectedOption?.flatMap(option => {
    //     if (option?.type === "Single Select") {
    //       return [
    //         {
    //           optionTitle: option?.optionTitle,
    //           optionID: option?._id,
    //           optionValueID: option?.values?.length
    //             ? option?.values[0]._id
    //             : "",
    //           optionValueName: option?.values?.length
    //             ? option?.values[0]?.name
    //             : "",
    //         },
    //       ];
    //     } else {
    //       return option?.values.map(value => ({
    //         optionTitle: option?.optionTitle,
    //         optionID: option?._id,
    //         optionValueID: value?._id,
    //         optionValueName: value?.name,
    //       }));
    //     }
    //   }),
    // }));

    if (selectedRow) {
      // setServiceIdSelected(serviceIdSelected);
      getProviders(activeOrder?.zipcode, selectedRow?.serviceid?._id);
    } else {
      getProviders(activeOrder?.zipcode, []);
    }
    setOpenModal(true);
    // }
  };

  const getProviders = (zipcode, serviceId) => {
    setLoading(true);
    let body = {
      serviceid: serviceId,
      zipcode: zipcode,
    };
    getProvidersByZipcodeAndService(body).then(async res => {
      console.log(res, "resres resres");
      let options = res?.data?.map(provider => ({
        label: provider?.legalname + " " + provider?.shortname,
        value: provider?._id,
        profileimage: provider?.image,
        companyId: provider?.compaines ? provider?.compaines[0] : "",
        exp: provider?.experience ? provider?.experience : "",
      }));
      setLoading(false);
      setNewProviders(res?.data);
      setNewProviderOptions(options);
    });
  };
  console.log(activeOrder, "act Order");
  return (
    <>
      <div>
        {isHotel === true && pastPendingTab === true ? (
          " "
        ) : providerId ? (
          <button
            className="btn btn-sm px-3 py-1 font-size-12 btn-primary-red"
            onClick={() => {
              toggle();
              getProvidersByZipCode(activeOrder);
            }}
          >
            Change Stylist
          </button>
        ) : (
          <button
            className="btn btn-sm px-3 py-1 font-size-12 btn-primary-red"
            onClick={() => {
              toggle();
              getProvidersByZipCode(activeOrder);
            }}
          >
            Assign Stylist
          </button>
        )}
      </div>

      {isHotel === true ? (
        <AssignStylist
          toggle={toggle}
          modal={modal}
          AvailableProviders={newProviderOptions}
          providers={newProviders}
          selecteditem={selectedRow}
          activeOrder={activeOrder}
          loading={loading}
          getallOrders={getallOrders}
          getOrderById={getOrderById}
          toggleModel={toggleModel}
        />
      ) : (
        <ChangeStylistModal
          toggle={toggle}
          modal={modal}
          AvailableProviders={newProviderOptions}
          providers={newProviders}
          selecteditem={selectedRow}
          activeOrder={activeOrder}
          loading={loading}
          getallOrders={getallOrders}
          getOrderById={getOrderById}
          toggleModel={toggleModel}
        />
      )}
    </>
  );
};

export default ChangeStylist;
