import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import Spinner from "components/spinner";
import noImage from "assets/images/PicUpload.jpg";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getIndviduals, deleteIndvidual } from "services/indvidual.service";
import fileIcon from "../../../src/assets/icons-image/document.png";
import ModalImage from "react-modal-image";

//redux
import MultipleCheckbox from "components/MutlpleCheckbox";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import IndividualCreate from "pages/ProviderRegister/individual/individualCreate";
import moment from "moment";
import StatusBar from "pages/ProviderRegister/individual/info/statusBar";
import StylistRating from "./Ratings";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import { Link } from "react-router-dom/cjs/react-router-dom";

const IndvidualListNew = props => {
  const [list, setList] = useState([]);
  const [idList, setIdList] = useState({});
  const [allList, setAllList] = useState([]);
  const [search, setSearch] = useState("");
  const [TotalList, setTotalList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [Statusmodal, setStatusModal] = useState(false);
  const [IndId, setIndId] = useState();
  const [tempList, setTempList] = useState([]);
  const [typeFilter, setTypeFilter] = useState(["Verified"]);
  const typeFilterOptions = ["pending", "Verified", "Rejected", "Blocked"];
  let UserId = JSON.parse(localStorage.getItem("authUser"))["_doc"]["_id"];
  let UserFirstname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "firstname"
  ];
  let UserLastname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "lastname"
  ];
  let Username = UserFirstname + " " + UserLastname;

  let userData = localStorage.getItem("authUser");
  let UserDetails = userData ? JSON.parse(userData)._doc : null;
  const userRole = UserDetails?.role || "";
  const adminRole = UserDetails?.adminRole || "";
  const toggle = () => {
    setModal(!modal);
  };
  const toggleSatus = () => {
    setStatusModal(!Statusmodal);
  };

  useEffect(() => {
    getAllList();
  }, []);
  const getAllList = () => {
    setLoading(true);
    getIndviduals()
      .then(res => {
        let result = res.data;
        // console.log(result, "indivduals data");
        setAllList(res?.data);
        setList(result);
        setTotalList(res?.data);
        setTempList(result);
        let obj = {};
        result.forEach(pr => {
          let id = pr._id;
          obj[id] = pr["access"];
        });
        setIdList(obj);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const removeProvider = id => {
    deleteIndvidual(id)
      .then(res => {
        setDelete_modal(false);
        getAllList();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeProvider(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };

  const handleRoute = id => {
    props.history.push(`/individul-info/${id}`);
  };

  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  const handleSearchByName = search => {
    if (search) {
      let tempList = _.cloneDeep(allList);
      let filterList = tempList.filter(
        ele =>
          ele?.legalname?.toLowerCase().includes(search.toLowerCase()) ||
          ele?.shortname?.toLowerCase().includes(search.toLowerCase())
      );
      setList(filterList);
    } else {
      setList(allList);
    }
  };
  const handleSearchByZipcode = search => {
    setSearch(search);
    if (search) {
      let tempList = _.cloneDeep(allList);
      let filterList = tempList.filter(item => {
        // const itemZipcode = item.zipcode.toLowerCase();
        // if (itemZipcode.includes(search)) {
        //   return true;
        // }
        return item.zipcodes.some(zipcodeObj =>
          zipcodeObj.zipcode.includes(search)
        );
      });
      setList(filterList);
    } else {
      setList(allList);
    }
  };
  function calculateExperienceFromDate(dateString) {
    const startDate = moment(dateString);
    const currentDate = moment();

    const totalMonths = currentDate.diff(startDate, "months");
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    return `${years} ${years === 1 ? "yr" : "yrs"}, ${months} ${
      months === 1 ? "MM" : "MM"
    }`;
  }

  useEffect(() => {
    // debugger;
    if (typeFilter?.length > 0) {
      const newList = tempList.filter(
        obj =>
          typeFilter.includes(obj.status) ||
          typeFilter.includes(obj?.adminVerification?.VerificationStatus)
      );
      setList(newList);
    } else {
      setList(tempList);
    }
  }, [typeFilter, tempList]);

  const handleCheckboxChange = text => {
    let newTypeFilter = [...typeFilter];
    let indx = newTypeFilter.indexOf(text);

    if (indx >= 0) {
      newTypeFilter.splice(indx, 1);
    } else {
      newTypeFilter.push(text);
    }
    setTypeFilter(newTypeFilter);
  };
  const checkboxItems = () => {
    return (
      <React.Fragment>
        {typeFilterOptions?.map((option, index) => {
          const isChecked = typeFilter.includes(option);
          return (
            <div className="d-flex me-3 align-items-center" key={index}>
              <Input
                key={option}
                id={option}
                type="checkbox"
                className="form-check-Inpu me-1 cursor-pointer"
                onChange={() => handleCheckboxChange(option)}
                defaultChecked={isChecked}
              />
              <Label
                className="form-check-Label fw-bold mb-0  pt-1  cursor-pointer"
                htmlFor={option}
              >
                {option}
              </Label>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Individuals | stylepro - -</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumbs title="Individuals" breadcrumbItem="Individual List" />
          <BreadcrumbSub
            subtile="Individuals List"
            SubBreadcrumbItem="Individual"
          />
          <div className="individual_list">
            <div className="d-flex align-items-center">
              <div>
                <h5 className="fw-bold">
                  Total Providers: {TotalList?.length}
                </h5>
              </div>
              <div className="search-box ms-5 individual_search">
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                  <input
                    className="form-control py-2 bg-white w-100"
                    type="search"
                    id="example-search-input"
                    placeholder="Search by Zipcode"
                    onChange={event => {
                      handleSearchByZipcode(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="search-box ms-4 individual_search">
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                  <input
                    className="form-control py-2 bg-white"
                    type="search"
                    id="example-search-input"
                    placeholder="Search by name"
                    onChange={event => {
                      handleSearchByName(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <Button
                type="button"
                color="primary"
                className=""
                onClick={() => {
                  toggle();
                }}
              >
                <i className="bx bx-user-plus me-1" />
                Create Individual
              </Button>
            </div>
          </div>

          {search ? (
            <div className="d-flex justify-content-start align-items-center mt-3">
              <div className="">
                <h6 className="mb-0">
                  Provider In ZipCode{" "}
                  <span className="text-danger ms-1 fw-bold">{search}</span>
                </h6>
              </div>
              <div className="ms-5 text-danger">
                {list?.length <= 0 ? (
                  <i>No results found</i>
                ) : (
                  <i>{list?.length} results found</i>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="d-flex mt-3 align-items-center">
            {checkboxItems()}
          </div>
          {list?.length === 0 ? (
            <Card className="mt-3">
              <CardBody>
                <div className="p-5 text-center">
                  <h5>No results found</h5>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card className="bg-light my-3">
              <CardBody className="">
                <Row className="services-card">
                  {list?.map((individual, index) => {
                    let FixedClass = "fw-bold ";
                    let statusClass =
                      individual?.status === "Verified"
                        ? FixedClass + "text-success"
                        : individual?.status === "pending"
                        ? FixedClass + "text-warning"
                        : FixedClass + "text-danger";
                    // const dateString = individual?.experience;
                    // const experience = calculateExperienceFromDate(dateString);
                    let partnerStatus =
                      individual?.adminVerification?.VerificationStatus;
                    // const partnershipstatus =
                    //   partnerStatus === "Active"
                    //     ? "text-success fw-bold"
                    //     : partnerStatus === "Blocked"
                    //     ? "text-danger fw-bold"
                    //     : "text-warning fw-bold";
                    return (
                      <Col
                        sm={6}
                        md={6}
                        xl={4}
                        xxl={4}
                        key={index}
                        className="mb-1"
                      >
                        <Card className="individual_cards">
                          <CardBody className="">
                            <div
                              className="view_button_1"
                              role="button"
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_PARTNER_PORTAL}/login/${individual?._id}/ind/ADMIN?adminId=${UserId}&name=${Username}`,
                                  "_blank",
                                  "width=1200,height=800"
                                )
                              }
                            >
                              <img src={fileIcon} alt="" className="me-1" />
                            </div>
                            {individual?.status === "Verified" &&
                            partnerStatus === "Active" ? (
                              ""
                            ) : (
                              <div className="Delete_button mt-1">
                                <Badge
                                  role="button"
                                  className="font-size-10 badge-soft-danger py-1 px-2 me-1 fw-bold"
                                  color="bg-danger"
                                  onClick={() => onClickDelete(individual?._id)}
                                >
                                  <i className="bx bx-trash-alt font-size-12 fw-bold"></i>
                                </Badge>
                              </div>
                            )}
                            <div className="d-flex">
                              <div>
                                <div>
                                  <img
                                    className=""
                                    src={
                                      !individual?.image ||
                                      individual?.image === "undefined"
                                        ? noImage
                                        : individual?.image
                                    }
                                    alt=""
                                    width={100}
                                    height={100}
                                    role="button"
                                  />
                                </div>

                                <div>
                                  {partnerStatus != "" ? (
                                    <div>
                                      <h6 className="text-center mt-2 parnership_status">
                                        {partnerStatus === "Active" ? (
                                          <i className="bx bx-check-shield text-success font-size-50"></i>
                                        ) : partnerStatus === "Blocked" ? (
                                          <i className="bx bx-block text-danger font-size-50"></i>
                                        ) : (
                                          ""
                                        )}
                                      </h6>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="ms-4">
                                <h6 className="fw-bold font-size-16 individual_name text-uppercase">
                                  {individual?.legalname}{" "}
                                  {individual?.shortname}
                                </h6>
                                {individual?.compaines?.length > 0 ? (
                                  <div className="mb-2">
                                    (
                                    <span className="">
                                      Working in{" "}
                                      {individual?.compaines[0]?.companyName}
                                    </span>
                                    )
                                  </div>
                                ) : (
                                  ""
                                )}
                                <h6 className="fw-bold" role="button">
                                  Reg #{individual?.regNo}
                                </h6>
                                {userRole === "Support" &&
                                adminRole === "frontDesk" ? (
                                  <div className="mb-1">
                                    <span className="fw-bold">Status: </span>
                                    <span className={statusClass}>
                                      {individual?.status}
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    className="mb-1"
                                    onClick={() => {
                                      toggleSatus();
                                      setIndId(individual?._id);
                                    }}
                                    role="button"
                                  >
                                    <span className="fw-bold">Status: </span>
                                    <span className={statusClass}>
                                      {individual?.status}
                                    </span>
                                  </div>
                                )}

                                <div className="mt-1">
                                  <span className="">
                                    Since:{" "}
                                    {individual?.createdAt &&
                                      moment(
                                        new Date(individual?.createdAt)
                                      ).format("DD MMM Y")}{" "}
                                  </span>
                                </div>
                                <div>
                                  {individual?.rating ? (
                                    <StylistRating value={individual?.rating} />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="mt-1 d-flex align-items-center mobile_number">
                                  <i className="bx bxs-phone"></i>{" "}
                                  <span className="fw-bold mb-0 ps-1">
                                    {individual?.mobileNumber}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
      <IndividualCreate toggle={toggle} modal={modal} reload={getAllList} />
      <StatusBar
        toggle={toggleSatus}
        modal={Statusmodal}
        id={IndId}
        reload={getAllList}
      />
      {delete_modal ? deletePopup() : null}
    </React.Fragment>
  );
};

export default IndvidualListNew;
