import React from "react";
import { Redirect } from "react-router-dom";
// Profile
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
//ProviderManagement
import ServicesList from "pages/Services/list";
//User View"
import PageList from "pages/pages/list";
import SlideList from "pages/slide/list";
import editSettings from "pages/globalSettings/edit";
import ZipcodeList from "pages/ZipCode/list";
import ParentCategoryNew from "pages/ParentCategoryNew";
import SubCategorieIndex from "pages/SubCategorieNew";
//Ecommerce
import ListBrand from "pages/Ecommerce/Brand/list";
import ListAttribute from "pages/Ecommerce/ProductAttributes/list";
import ProductsListEcommerce from "pages/Ecommerce/Products/List";
import ProductCatListEcommerce from "pages/Ecommerce/ProductCategory/List";
import UserIndex from "pages/Users2";
import CreateEmployee from "pages/Employees/CreateEmployee";
import { RatingsQuestions } from "pages/RatingQuestions";
import ProductEditIndex from "pages/Ecommerce/Products/ProductEditIndex";
import EcomOrders from "pages/Ecommerce/Orders";
import ArticleIndex from "pages/Blogs";
import LabelsPage from "pages/MobileLabels/LabelsPage";
import Authorsindex from "pages/Authors";
import IndvidualListNew from "pages/Individual-New/list";
import ArtistList from "pages/Artist/list";
import ArtistInfo from "pages/Artist/info";
import CompaniesList from "pages/Company/list";
import ArtistForms from "pages/ArtistQuoteForms";
import PaymentList from "pages/Payment/list";
import ContactUsUsers from "pages/Contact-us";
// import CreateCoupon from "pages/CouponManagement/Create";
import CouponLists from "pages/CouponManagementt";
import CouponsCount from "pages/CouponManagementt/CouponsCount";
import SettlementList from "pages/Settlements/list";
import ExceptionsSummary from "pages/App-Exceptions";
import ApiExceptionsList from "pages/App-Exceptions/api";
import AppExceptionsList from "pages/App-Exceptions/app";
import SettlementsByCode from "pages/Settlements/SettlementsByCode";
import SettlementsSummaryStylist from "pages/Settlements";
import SettlementsSummaryCompany from "pages/Settlements/summaryCompany";
import AvatarList from "pages/Avatar";
import Order_Details_index from "pages/All-Order-Details";
import AdminOrdersList from "pages/Orders-v2";
import SlotsIndex from "pages/StylistSlots/SlotsIndex";
import HotelIndexPage from "pages/Hotels";
import HotelLogs from "pages/HotelLogs";
import HotelLogsList from "pages/HotelLogs/HotelLogsList";
import HotelOrdersindexPage from "pages/Hotel_orders_statements";
import Page404 from "utils/404";

const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    allowedRoles: ["Admin", "manager", "frontDesk", "accountant"],
  },

  { path: "/Services-list", component: ServicesList, allowedRoles: ["Admin"] },

  { path: "/payments-list", component: PaymentList, allowedRoles: ["Admin"] },

  { path: "/app-settings", component: editSettings, allowedRoles: ["Admin"] },

  { path: "/page-list", component: PageList, allowedRoles: ["Admin"] },

  { path: "/slide-list", component: SlideList, allowedRoles: ["Admin"] },
  { path: "/avatar-list", component: AvatarList, allowedRoles: ["Admin"] },
  { path: "/orders", component: AdminOrdersList, allowedRoles: ["Admin"] },

  //Zip Code
  { path: "/zipcode", component: ZipcodeList, allowedRoles: ["Admin"] },

  {
    path: "/Provider-list-individul",
    component: IndvidualListNew,
    allowedRoles: ["Admin", "frontDesk"],
  },
  {
    path: "/Provider-list-company",
    component: CompaniesList,
    allowedRoles: ["Admin"],
  },

  // Artist
  {
    path: "/Provider-list-artist",
    component: ArtistList,
    allowedRoles: ["Admin"],
  },
  { path: "/artist/:id", component: ArtistInfo, allowedRoles: ["Admin"] },
  { path: "/Artist-Forms", component: ArtistForms, allowedRoles: ["Admin"] },
  {
    path: "/parentcategory-new",
    component: ParentCategoryNew,
    allowedRoles: ["Admin"],
  },
  //users2
  { path: "/Users-list", component: UserIndex, allowedRoles: ["Admin"] },
  {
    path: "/SubCategorieNew",
    component: SubCategorieIndex,
    allowedRoles: ["Admin"],
  },
  //Ecommerce
  { path: "/list-brand", component: ListBrand, allowedRoles: ["Admin"] },
  {
    path: "/list-attribute",
    component: ListAttribute,
    allowedRoles: ["Admin"],
  },
  {
    path: "/products-list",
    component: ProductsListEcommerce,
    allowedRoles: ["Admin"],
  },
  {
    path: "/product/:id",
    component: ProductEditIndex,
    allowedRoles: ["Admin"],
  },
  {
    path: "/product/product-create/:catId",
    component: ProductEditIndex,
    allowedRoles: ["Admin"],
  },
  {
    path: "/products-category-list",
    component: ProductCatListEcommerce,
    allowedRoles: ["Admin"],
  },
  { path: "/ecom-orders", component: EcomOrders, allowedRoles: ["Admin"] },
  { path: "/articles", component: ArticleIndex },
  {
    path: "/ratings-questions",
    component: RatingsQuestions,
    allowedRoles: ["Admin"],
  },
  { path: "/labels", component: LabelsPage, allowedRoles: ["Admin"] },
  { path: "/Authors", component: Authorsindex, allowedRoles: ["Admin"] },
  {
    path: "/settlements-summary-individual",
    component: SettlementsSummaryStylist,
    allowedRoles: ["Admin"],
  },
  {
    path: "/settlements-summary-company",
    component: SettlementsSummaryCompany,
    allowedRoles: ["Admin"],
  },
  {
    path: "/settlements/:id",
    component: SettlementList,
    allowedRoles: ["Admin"],
  },
  {
    path: "/exceptions/app",
    component: AppExceptionsList,
    allowedRoles: ["Admin"],
  },
  {
    path: "/exceptions/api",
    component: ApiExceptionsList,
    allowedRoles: ["Admin"],
  },
  {
    path: "/exceptions",
    component: ExceptionsSummary,
    allowedRoles: ["Admin"],
  },
  //Individual Slots
  { path: "/Slots", component: SlotsIndex, allowedRoles: ["Admin"] },
  //contact-us
  {
    path: "/contact-us-users",
    component: ContactUsUsers,
    allowedRoles: ["Admin"],
  },
  //Hotels
  { path: "/Hotels-list", component: HotelIndexPage, allowedRoles: ["Admin"] },
  { path: "/Hotel-Logs", component: HotelLogs, allowedRoles: ["Admin"] },
  {
    path: "/Hotel-Logs-List/:id",
    component: HotelLogsList,
    allowedRoles: ["Admin"],
  },
  {
    path: "/Hotel_orders_statemets",
    component: HotelOrdersindexPage,
    allowedRoles: ["Admin", "manager"],
  },
  //Coupons
  {
    path: "/Coupon-mangement",
    component: CouponLists,
    allowedRoles: ["Admin"],
  },
  {
    path: "/Coupon-users/:code",
    component: CouponsCount,
    allowedRoles: ["Admin"],
  },
  {
    path: "/SettlementsByCode/:id",
    component: SettlementsByCode,
    allowedRoles: ["Admin"],
  },
  {
    path: "/statements_list",
    component: Order_Details_index,
    allowedRoles: ["Admin", "accountant"],
  },
  { path: "/create-user", component: CreateEmployee, allowedRoles: ["Admin"] },

  {
    path: "/edit-user/:id",
    component: CreateEmployee,
    allowedRoles: ["Admin"],
  },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];
const privateRoutes = [];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/404", component: Page404 },
];

export { publicRoutes, authProtectedRoutes, privateRoutes };
//**********CREATE USER *****************//
// { path: "/User-list", component: Customers, allowedRoles: ["Admin"] },
// { path: "/create-user", component: CreateEmployee, allowedRoles: ["Admin"] },
// { path: "/user-view/:id", component: UserView, allowedRoles: ["Admin"] },
// {
//   path: "/edit-user/:id",
//   component: CreateEmployee,
//   allowedRoles: ["Admin"],
// },
//**********CATEGOTY-LIST *****************//
// { path: "/category-list", component: CreateList, allowedRoles: ["Admin"] },
// {
//   path: "/category-create",
//   component: CreateCategory,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/category-update/:id",
//   component: CreateCategory,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/category-view/:id",
//   component: ViewCategory,
//   allowedRoles: ["Admin"],
// },

//**********SERVICES-LIST *****************//
// {
//   path: "/Services-create",
//   component: CreateService,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/services-update/:id",
//   component: CreateService,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/services-view/:id",
//   component: ViewService,
//   allowedRoles: ["Admin"],
// },

// { path: "/bookings-list", component: BookingList },
// { path: "/create-type", component: CreateType, allowedRoles: ["Admin"] },

//**********CREATE-GALLERY-FOR-PROVIDER*****************//

// { path: "/gallery-list", component: GalleryList },
// {
//   path: "/create-gallery",
//   component: CreateGallery,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/edit-gallery/:id",
//   component: CreateGallery,
//   allowedRoles: ["Admin"],
// },

//**********GLOBAL_SETTINGS*****************//

// {
//   path: "/global-settings",
//   component: SettingsList,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/create-settings",
//   component: GlobalSettings,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/edit-settings/:id",
//   component: GlobalSettings,
//   allowedRoles: ["Admin"],
// },

//**********PACKAGES_LIST*****************//

// { path: "/packages-list", component: PackageList, allowedRoles: ["Admin"] },
// {
//   path: "/packages-create",
//   component: CreatePackage,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/packages-update/:id",
//   component: CreatePackage,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/package-view/:id",
//   component: ViewPackage,
//   allowedRoles: ["Admin"],
// },

// { path: "/order/:id", component: ViewOrder, allowedRoles: ["Admin"] },
// { path: "/providers", component: providerlist, allowedRoles: ["Admin"] },
// { path: "/providers", component: Providers, allowedRoles: ["Admin"] },
// { path: "/provider-type", component: ProviderType, allowedRoles: ["Admin"] },
// { path: "/update-type/:id", component: CreateType, allowedRoles: ["Admin"] },
// { path: "/provider-list", component: ProviderList, allowedRoles: ["Admin"] },
// {
//   path: "/provider-request",
//   component: ProviderRequest,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/create-provider",
//   component: CreateProvider,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/provider-update/:id",
//   component: CreateProvider,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/provider-view/:id",
//   component: ProviderView,
//   allowedRoles: ["Admin"],
// },
// //profile
// { path: "/profile", component: UserProfile, allowedRoles: ["Admin"] },
//user
// { path: "/request-view", component: RequestView, allowedRoles: ["Admin"] },

// Brands
//**********BRANDS*****************//

// { path: "/brands", component: BrandList, allowedRoles: ["Admin"] },
// { path: "/brand-view/:id", component: ViewBrand, allowedRoles: ["Admin"] },
// { path: "/brand-create", component: CreateBrand, allowedRoles: ["Admin"] },
// {
//   path: "/brand-update/:id",
//   component: CreateBrand,
//   allowedRoles: ["Admin"],
// },

//**********PRODUCTS*****************//

// { path: "/products", component: ProductsList, allowedRoles: ["Admin"] },
// {
//   path: "/product-view/:id",
//   component: ViewProduct,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/product-create",
//   component: CreateProduct,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/product-update/:id",
//   component: CreateProduct,
//   allowedRoles: ["Admin"],
// },
//sub-category
// {
//   path: "/parentcategory-list",
//   component: ParentCatList,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/parentcategory-create",
//   component: CreateParentcategory,
//   allowedRoles: ["Admin"],
// },
// {
//   path: "/parentcategory-update/:id",
//   component: CreateParentcategory,
//   allowedRoles: ["Admin"],
// },
// { path: "/parentcategory-view/:id", component: ViewParentCat ,allowedRoles: ["Admin"],},
// { path: "/page-create", component: CreatePage, allowedRoles: ["Admin"] },
// { path: "/page-update/:id", component: CreatePage, allowedRoles: ["Admin"] },
// { path: "/slide-create", component: CreateSlide, allowedRoles: ["Admin"] },
// {
//   path: "/slide-update/:id",
//   component: CreateSlide,
//   allowedRoles: ["Admin"],
// },
// { path: "/zipcode-create", component: ZipcodeCreate,allowedRoles: ["Admin"], },
// { path: "/zipcode-update/:id", component: ZipcodeCreate ,allowedRoles: ["Admin"],},
//Quick Services Code
// { path: "/quick-services-list", component: QuickServicesList ,allowedRoles: ["Admin"],},
// { path: "/quick-service-create", component: CreateQuickService,allowedRoles: ["Admin"], },
// { path: "/quick-service-update/:id", component: CreateQuickService ,allowedRoles: ["Admin"],},
// { path: "/quick-service-view/:id", component: ViewQuickService ,allowedRoles: ["Admin"],},
//Provider Register
// {
//   path: "/Provider-Register-individul",
//   component: ProviderRegisterIndividual,
//   allowedRoles: ["Admin"],
// },
// { path: "/Provider-Register-company", component: CompanyRegister,allowedRoles: ["Admin"], },
// { path: "/Provider-list-individul", component: indvidualList },

// { path: "/Provider-list-company", component: CompanyList },
// { path: "/FileMan", component: FileMan,allowedRoles: ["Admin"], },
// { path: "/ArtistForm-View/:id", component: ArtistQuoteView },
// { path: "/service-cost", component: ServiceCost },
// { path: "/users2/:id", component: UserDetails },
// { path: "/products-category-create", component: ProductCategoryCreate },
// { path: "/Reviews", component: Reviews },
// { path: "/ecom-products", component: EcommerceProducts },
// { path: "ecommerce-product-detail", component: EcommerceProductDetail },
// { path: "/e-categories", component: Ecomcategories },
// { path: "/company-addMember/:id", component: AddMember },
// { path: "/company-info/:id", component: CompanyInfo },

// { path: "/individul-expertise/:id", component: IndividualExpertise },
// { path: "/individul-locations/:id", component: ServiceLocations },
// { path: "/individul-info/:id", component: IndividualInfo },
// { path: "/individul-join-company/:id", component: JoinCompany },
// { path: "/create-brand", component: BrandCreate },
// { path: "/product-create-attributes", component: CreateAttributes },
// { path: "/product-create-index", component: ProductsIndex },
