import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const { t } = props;
  const ref = useRef();

  // User data
  let userData = localStorage.getItem("authUser");
  let UserDetails = userData ? JSON.parse(userData)._doc : null;
  const userRole = UserDetails?.role || "";
  const adminRole = UserDetails?.adminRole || "";

  // Filter menu items based on user roles
  const menuItems = [
    { type: "title", label: "Menu" },
    {
      path: "/",
      icon: "bx bx-home-circle",
      label: "Dashboard",
      roles: ["Admin"],
      adminRoles: ["super"],
    },
    {
      path: "/#",
      icon: "bx bx-cog",
      label: "Settings",
      subMenu: [
        { path: "/zipcode", label: "Zip Code Enabler" },
        { path: "/app-settings", label: "App Settings" },
        { path: "/slide-list", label: "Slides" },
        { path: "/page-list", label: "Pages" },
        { path: "/avatar-list", label: "Avatars" },
        { path: "/payments-list", label: "Payment Methods" },
      ],
    },

    { type: "title", label: "Company Order Data" },
    { path: "/statements_list", icon: "bx bx-detail", label: "Statements" },
    {
      path: "/Hotel_orders_statemets",
      icon: "bx bx-detail",
      label: "Hotel Order Statements",
    },
    { path: "/orders", icon: "bx bx-receipt", label: "Orders" },
    { path: "/ecom-orders", icon: "bx bx-basket", label: "E-Orders" },
    {
      path: "/Artist-Forms",
      icon: "bx bx-paint",
      label: "Makeup Quote/Orders",
    },
    { path: "/Slots", icon: "bx bx-calendar-event", label: "Events" },
    { path: "/Users-list", icon: "bx bx-user-plus", label: "Customer Data" },
    {
      path: "/contact-us-users",
      icon: "bx bx-user",
      label: "Contact-Us Queries",
    },

    { type: "title", label: "Provider Information" },
    {
      path: "/Provider-list-individul",
      icon: "bx bx-user",
      label: "Individual",
      roles: ["Admin", "Support"],
      adminRoles: ["super", "frontDesk"],
    },
    {
      path: "/Provider-list-company",
      icon: "bx bx-building-house",
      label: "Company",
    },
    { path: "/Provider-list-artist", icon: "bx bx-paint", label: "Artist" },

    { type: "title", label: "Service Categories" },
    {
      path: "/parentcategory-new",
      icon: "bx bx-notepad",
      label: "Parent Category",
    },
    {
      path: "/SubCategorieNew",
      icon: "bx bx-spreadsheet",
      label: "Sub Category",
    },
    {
      path: "/Services-list",
      icon: "bx bx-purchase-tag-alt",
      label: "Services",
    },
    { path: "/Hotels-list", icon: "bx bx-buildings", label: "Hotels" },
    { path: "/Hotel-Logs", icon: "bx bx-error", label: "Hotel Logs" },

    { type: "title", label: "Ecommerce Categories" },
    { path: "/list-brand", icon: "bx bx-gift", label: "E-Brands" },
    { path: "/list-attribute", icon: "bx bx-list-plus", label: "E-Variants" },
    { path: "/products-list", icon: "bx bx-basket", label: "E-Products" },
    {
      path: "/products-category-list",
      icon: "bx bx-receipt",
      label: "E-Product Categories",
    },

    { type: "title", label: "Management" },
    { path: "/articles", icon: "bx bxs-book-content", label: "Articles" },
    { path: "/Coupon-mangement", icon: "bx bxs-coupon", label: "Coupons" },

    { type: "title", label: "Mobile Configue" },
    { path: "/labels", icon: "bx bx-image", label: "Mobile HomePage" },

    { type: "title", label: "Service reviews" },
    {
      path: "/ratings-questions",
      icon: "bx bx-help-circle",
      label: "Service Questions",
    },

    { type: "title", label: "Orders Settlements" },
    {
      path: "/settlements-summary-individual",
      icon: "bx bx-user",
      label: "Individual",
    },
    {
      path: "/settlements-summary-company",
      icon: "bx bx-buildings",
      label: "Company",
    },

    { type: "title", label: "App Exceptions" },
    { path: "/exceptions", icon: "bx bx-error", label: "Exceptions" },
  ];

  let filteredMenuItems = [];
  let lastTitle = null;

  menuItems.forEach(item => {
    if (item.type === "title") {
      lastTitle = item;
    } else {
      const isSuperAdmin = userRole === "Admin" && adminRole === "super";
      const isSupportFrontDesk =
        userRole === "Support" && adminRole === "frontDesk";
      const isAdminManager = userRole === "Customer" && adminRole === "manager";
      const isAccountant =
        userRole === "Customer" && adminRole === "accountant";
      let isAuthorized = false;

      if (isSuperAdmin) {
        // Super Admin: Show everything
        isAuthorized = true;
      } else if (isSupportFrontDesk) {
        // Support FrontDesk: Show only "Individual" under "Provider Information"
        isAuthorized = item.path === "/Provider-list-individul";
      } else if (isAdminManager) {
        // Admin Manager: Show only "Hotel Order Statements"
        isAuthorized = item.path === "/Hotel_orders_statemets";
      } else if (isAccountant) {
        // Admin accountant: Show only "Order Statements"
        isAuthorized = item.path === "/statements_list";
      } else {
        // Default role-based filtering
        isAuthorized =
          (item.roles?.includes(userRole) ?? true) &&
          (item.adminRoles?.includes(adminRole) ?? true);
      }

      if (isAuthorized) {
        if (lastTitle) {
          filteredMenuItems.push(lastTitle);
          lastTitle = null;
        }
        filteredMenuItems.push(item);
      }
    }
  });

  // Initialize MetisMenu for collapsible submenus
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {filteredMenuItems.map((item, index) =>
              item.type === "title" ? (
                <li key={index} className="menu-title">
                  {t(item.label)}
                </li>
              ) : (
                <li key={index}>
                  <Link to={item.path}>
                    <i className={item.icon}></i>
                    <span>{t(item.label)}</span>
                  </Link>
                  {item.subMenu && (
                    <ul className="sub-menu" aria-expanded="false">
                      {item.subMenu.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Link to={subItem.path}>{t(subItem.label)}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(SidebarContent));

{
  {
    /* <li>
              <Link to="/#" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
      
            </li> */
  }
  /* <li>
                  <Link to="/parentcategory-list">
                    {props.t("Parent Category")}
                  </Link>
                </li> */
  {
    /* <li>
              <Link to="/provider-management" className=" ">
                <i className="bx bx-calendar"></i>
                <span>{props.t("Provider Management")}</span>
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/packages-list">
                <i className="bx bx-archive"></i>
                {props.t("Packages")}
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/packages-list" className="">
                <i className="bx bx-archive"></i>
                <span>{props.t("Packages")}</span>
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/orders" className="">
                <i className="bx bx-receipt"></i>
                <span> {props.t("Orders")}</span>
              </Link>
            </li> */
  }

  {
    /* */
  }
  {
    /* <li>
              <Link to="/mobile-login" className="">
                <i className="bx bx-receipt"></i>
                <span> {props.t("Login")}</span>
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/order2" className="">
                <i className="bx bx-receipt"></i>
                <span> {props.t("Orders2")}</span>
              </Link>
            </li> */
  }
  {
    /* <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Provider-list-company">{props.t("company")}</Link>
                </li>
                <li>
                  <Link to="/FileMan">{props.t("New Service")}</Link>
                </li>
                <li>
                  <Link to="/service-cost">{props.t("service-cost1")}</Link>
                </li>
              </ul> */
  }
  {
    /* <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Provider-list-individul">
                    {props.t("Individual")}
                  </Link>
                </li>
              </ul> */
  }
  {
    /* <li>
              <Link to="/settingsView" className="">
                <i className="bx bx-cog "></i>
                <span>{props.t("Settings New")}</span>
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/#">
                <i className="bx bx-group"></i>
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/User-list">{props.t("Users List")}</Link>
                </li>
              </ul>
            </li> */
  }
  {
    /* <li>
                  <Link to="/global-settings" >
                    {props.t("Global Settings")}
                  </Link>

                </li> */
  }
  {
    /* <li>
                  <Link to="/Services-list">{props.t("Services")}</Link>
                </li> */
  }
  {
    /* <li>
                  <Link to="/quick-services-list">
                    {props.t("Quick Services")}
                  </Link>
                </li> */
  }

  {
    /* <li>
                  <Link to="/Services-create">
                    {props.t("Create Services")}
                  </Link>
                </li> */
  }
  {
    /* <li>
                  <Link to="/brands">{props.t("Brands")}</Link>
                </li> */
  }

  {
    /* <li><Link to="/brand-create">
                  {props.t("Create Brand")}
                </Link>
                </li> */
  }
  {
    /* <li>
                  <Link to="/parentcategory-create">
                    {props.t("Create Parent Category")}
                  </Link>
                </li> */
  }
  {
    /* <li>
                  <Link to="/category-list">{props.t("Sub Category")}</Link>
                </li> */
  }
  {
    /* <li> <Link to="/category-create">
                  {props.t("Sub Create Category")}
                </Link>
                </li> */
  }
  {
    /* <li>
                  {" "}
                  <Link to="/products">{props.t("Products")}</Link>
                </li> */
  }
  {
    /* <li><Link to="/product-create">
                  {props.t("Create Product")}
                </Link></li> */
  }
  {
    /* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-user-check"></i>
                <span>{props.t("providers")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/provider-type">{props.t("Provider Type")}</Link>
                </li>
                <li>
                  <Link to="/provider-list">{props.t("Provider")}</Link>
                </li>
                <li>
                  <Link to="/gallery-list">{props.t("Gallery-list")}</Link>
                </li>
              </ul>
            </li> */
  }
  {
    /* <li>
              <Link to="/provider-management" className=" ">
                <i className="bx bx-calendar"></i>
                <span>{props.t("Provider Management")}</span>
              </Link>
            </li> */
  }
  {
    /* <li className="menu-title">{props.t("Authors")}</li>
            <li>
              <Link to="/Authors" className="">
                <i className="bx bx-user"></i>
                <span> {props.t("Authors")}</span>
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/Artist-services-list" className=" ">
                <i className="bx bx-purchase-tag"></i>
                <span>{props.t("Artist Services")}</span>
              </Link>
            </li> */
  }
}
