import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spinner from "components/spinner";
import profile from "assets/images/profile-img.png";
import { getOTPforForgotPassword, resetPassword } from "services/auth.service";
import logo from "../../assets/images/logo-sm-light.png";
import SweetAlert from "react-bootstrap-sweetalert";
import stylifLogo from "../../assets/images/stylifyLogoRed.png";
const ForgetPasswordPage = props => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState("email");
  const [resOTP, setResOTP] = useState();
  const [resetUrl, setResetUrl] = useState("");
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);
  console.log(resetUrl, "resetUrlllll");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      otp: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      email:
        step === "email"
          ? Yup.string().required("Please enter your email")
          : null,
      otp: step === "otp" ? Yup.string().required("Please enter OTP") : null,
      password:
        step === "reset"
          ? Yup.string().required("Please enter new password")
          : null,
      confirmPassword:
        step === "reset"
          ? Yup.string()
              .required("Please confirm your password")
              .oneOf([Yup.ref("password"), null], "Passwords must match")
          : null,
    }),
    onSubmit: values => {
      if (step === "email") {
        handleSendOTP(values);
      } else if (step === "otp") {
        handleVerifyOTP(values);
      } else if (step === "reset") {
        handleResetPassword(values);
      }
    },
  });

  const handleSendOTP = async values => {
    setLoading(true);
    try {
      const res = await getOTPforForgotPassword(values);
      if (res?.success) {
        setResOTP(res.otp);
        setResetUrl(
          res?.resetUrl.replace(/https?:\/\/(dev|prod)\.stylify\.com\//, "")
        );
        setStep("otp");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
    setLoading(false);
  };

  const handleVerifyOTP = values => {
    if (values.otp == resOTP) {
      setStep("reset");
    } else {
      alert("Invalid OTP");
    }
  };

  const handleResetPassword = async values => {
    setLoading(true);
    try {
      let res = await resetPassword(resetUrl, { password: values?.password });
      if (res?.success === true) {
        // alert("Password reset successful!");
        setsuccess_msg(true);
      } else {
        seterror_msg(true);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
    }
    setLoading(false);
  };
  const leaveToList = () => {
    props.history.push("/login");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully password changed
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              ok
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | stylepro</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <Spinner loading={loading} />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      {step === "email" && (
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            Forgot your password, not a problem?
                          </h5>
                          <p className="font-size-10">
                            Enter your email address below and you will receieve
                            an OTP to reset your password.
                          </p>
                        </div>
                      )}
                      {step === "otp" && (
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Reset Password</h5>
                          <p className="font-size-10">
                            Enter OTP to reset your password.
                          </p>
                        </div>
                      )}
                    </Col>
                    {step === "reset" ? (
                      <Col className="col-5 align-self-end">
                        <img src={stylifLogo} alt="" className="img-fluid" />
                      </Col>
                    ) : (
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    )}
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle ">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                    >
                      {step === "email" && (
                        <div className="mb-3">
                          <Label>Email</Label>
                          <Input
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            invalid={
                              !!(formik.touched.email && formik.errors.email)
                            }
                          />
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                        </div>
                      )}

                      {step === "otp" && (
                        <div className="mb-3">
                          <p>
                            {" "}
                            Please enter the 4 digit code sent to{" "}
                            {formik.values.email}
                          </p>
                          <Input
                            type="text"
                            name="otp"
                            placeholder="Enter OTP"
                            value={formik.values.otp}
                            onChange={formik.handleChange}
                            invalid={
                              !!(formik.touched.otp && formik.errors.otp)
                            }
                          />
                          <FormFeedback>{formik.errors.otp}</FormFeedback>
                        </div>
                      )}

                      {step === "reset" && (
                        <>
                          {/* <div className="mb-3">
                            <Label>New Password</Label>
                            <Input
                              type="password"
                              name="password"
                              placeholder="Enter new password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              invalid={
                                !!(
                                  formik.touched.password &&
                                  formik.errors.password
                                )
                              }
                            />
                            <FormFeedback>
                              {formik.errors.password}
                            </FormFeedback>
                          </div>
                          <div className="mb-3">
                            <Label>Confirm Password</Label>
                            <Input
                              type="password"
                              name="confirmPassword"
                              placeholder="Confirm new password"
                              value={formik.values.confirmPassword}
                              onChange={formik.handleChange}
                              invalid={
                                !!(
                                  formik.touched.confirmPassword &&
                                  formik.errors.confirmPassword
                                )
                              }
                            />
                            <FormFeedback>
                              {formik.errors.confirmPassword}
                            </FormFeedback>
                          </div> */}
                          <div className="mb-3">
                            <Label>New Password</Label>
                            <InputGroup>
                              <Input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Enter new password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                invalid={
                                  !!(
                                    formik.touched.password &&
                                    formik.errors.password
                                  )
                                }
                              />
                              <InputGroupText
                                onClick={togglePasswordVisibility}
                                style={{ cursor: "pointer" }}
                              >
                                <i
                                  className={
                                    showPassword ? "bx bx-hide" : "bx bx-show"
                                  }
                                ></i>
                              </InputGroupText>
                              <FormFeedback>
                                {formik.errors.password}
                              </FormFeedback>
                            </InputGroup>
                          </div>
                          <div className="mb-3">
                            <Label>Confirm Password</Label>
                            <InputGroup>
                              <Input
                                type={showConfirmPassword ? "text" : "password"}
                                name="confirmPassword"
                                placeholder="Confirm new password"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                invalid={
                                  !!(
                                    formik.touched.confirmPassword &&
                                    formik.errors.confirmPassword
                                  )
                                }
                              />
                              <InputGroupText
                                onClick={toggleConfirmPasswordVisibility}
                                style={{ cursor: "pointer" }}
                              >
                                <i
                                  className={
                                    showConfirmPassword
                                      ? "bx bx-hide"
                                      : "bx bx-show"
                                  }
                                ></i>
                              </InputGroupText>
                              <FormFeedback>
                                {formik.errors.confirmPassword}
                              </FormFeedback>
                            </InputGroup>
                          </div>
                        </>
                      )}

                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md"
                            type="submit"
                          >
                            {step === "email"
                              ? "Send Code"
                              : step === "otp"
                              ? "Verify OTP"
                              : "Reset Password"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p className="mb-2">
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>
                </p>
                <p className="font-size-10">
                  © {new Date().getFullYear()} Stylepro Private Limited. All
                  Rights Reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        {success_msg ? successPopup() : null}
        {error_msg ? errorPopup() : null}
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
