import Breadcrumb from "components/Common/Breadcrumb";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import HotelOrdersStatementsList from "./list";
import { getAllHotelsList } from "services/V2_APIs/hotel.service";

const HotelOrdersindexPage = () => {
  const [hotels, setHotels] = useState([]);
  const buildOptions = list => {
    const result = list?.map(ele => {
      return {
        label: ele?.name,
        value: ele?.name,
        id: ele?._id,
      };
    });
    const viewAllOption = {
      label: "View All",
      value: "View All",
    };

    return [...result, viewAllOption];
  };
  useEffect(() => {
    getAllHotels();
  }, []);
  const getAllHotels = () => {
    getAllHotelsList()
      .then(res => {
        if (res?.success === true) {
          setHotels(buildOptions(res?.data));
        } else {
          setHotels([]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <div>
      <React.Fragment>
        <div className="all_orders_details">
          <div className="page-content mb-5">
            <Container fluid>
              <MetaTags>
                <title>Orders</title>
              </MetaTags>
              <Breadcrumb title="Orders" breadcrumbItem="All Orders List" />
              <BreadcrumbSub
                subtile="Statements"
                SubBreadcrumbItem="Statements"
              />
              <HotelOrdersStatementsList HotelsList={hotels}/>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default HotelOrdersindexPage;
