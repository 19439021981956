import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isPrivate,
  allowedRoles = [],
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const authUser = localStorage.getItem("authUser");
      if (isAuthProtected && !authUser) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      if (isAuthProtected && authUser) {
        const user = JSON.parse(authUser);
        const userRole = user["_doc"]["role"];
        const adminRole = user["_doc"]["adminRole"]; // If applicable
        const isSuperAdmin = userRole === "Admin" && adminRole === "super";
        const hasAccess =
          isSuperAdmin ||
          allowedRoles?.includes("Admin") ||
          allowedRoles?.includes("frontDesk") ||
          allowedRoles?.includes("manager") ||
          allowedRoles?.includes("accountant");  

        if (!hasAccess) {
          return <Redirect to={{ pathname: "/dashboard" }} />;
        }
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  allowedRoles: PropTypes.array, // New prop for role-based access
};

export default Authmiddleware;
