import { getStatusByBgClass } from "components/Common/GetStatus";
import ProfileImage from "components/profileImage";
import Instructions from "pages/Orders-v2/Instructions";
import OrderDetailsTable from "pages/Orders-v2/orderDetailsTable";
import ServiceOrderHistory from "pages/Orders-v2/OrderHistory";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import MomentTimezone from "moment-timezone";
import noImage from "assets/images/PicUpload.jpg";
import SlotsArray from "utils/Slots";
const StatementsOrderDetailsModal = ({
  modal,
  toggle,
  activeOrder,
  manager,
}) => {
  const [order, setOrder] = useState({});
  const [totalAmount, setTotalAmount] = useState();
  const [Amount, setAmount] = useState();
  useEffect(() => {
    if (activeOrder && activeOrder.length) {
      const currentOrder = { ...activeOrder[0] };
      currentOrder.createdAt = MomentTimezone.tz(
        currentOrder.createdDate,
        currentOrder?.timeZone || "Asia/Kolkata"
      ).format("DD-MMMM-YYYY @ h:mm a");
      currentOrder.appointmentDate = MomentTimezone.tz(
        currentOrder.appointmentDate,
        currentOrder?.timeZone || "Asia/Kolkata"
      ).format("DD-MMMM-YYYY");
      setOrder(currentOrder);
      let totalAmount = 0;
      currentOrder?.selectedList?.forEach(service => {
        service?.status === "Complete" ? (totalAmount += service.amount) : 0;
      });
      let Amount = 0;
      currentOrder?.selectedList?.forEach(service => {
        Amount += service.amount;
      });
      const discountInfo = currentOrder?.discountInfo;
      if (discountInfo) {
        if (discountInfo?.discountType === "Amount") {
          // Subtract flat discount amount
          totalAmount -= parseFloat(discountInfo.discountAmount);
          Amount -= parseFloat(discountInfo.discountAmount);
        } else if (discountInfo?.discountType === "Percentage") {
          // Subtract percentage from total amount
          const discountPercentage = parseFloat(discountInfo?.discountAmount);
          totalAmount -= (totalAmount * discountPercentage) / 100;
          Amount -= (Amount * discountPercentage) / 100;
        }
      }
      setTotalAmount(totalAmount?.toFixed(2));
      setAmount(Amount?.toFixed(2));
    }
  }, [activeOrder]);
  let stylistnotavailable = order?.notAvailable == true;

  const getSlotTimes = selectedList => {
    let stylistMap = new Map();

    selectedList.forEach(item => {
      const slotNumber = item?.slots[0]?.toString();
      const slotExists = Object.keys(SlotsArray).includes(slotNumber);
      const stylistKey = item?.providerid?.mobileNumber;

      let existingStylist = stylistMap.get(stylistKey);

      if (existingStylist) {
        if (!existingStylist.slots.includes(slotNumber)) {
          existingStylist.slots.push(slotNumber);
          if (slotExists) {
            existingStylist.slotTimes.push(SlotsArray[slotNumber]);
          } else {
            existingStylist.slotTimes.push(null);
          }
        }
      } else {
        stylistMap.set(stylistKey, {
          stylistName: item?.providerid?.legalname,
          stylistShortName: item?.providerid?.shortname,
          stylistMobile: item?.providerid?.mobileNumber,
          stylistImage: item?.providerid?.image,
          slots: [slotNumber],
          slotTimes: [slotExists ? SlotsArray[slotNumber] : null],
        });
      }
    });

    return Array.from(stylistMap.values());
  };
  const renderAddress = () => {
    if (typeof order?.address === "string") {
      let address, area, landmark, areaDetails;
      const checkAddress = order?.address?.includes("||");
      if (checkAddress) {
        address = order.address.split("||");
        area = address[1] && address[3] ? address[1] + "," + address[3] : "";
        areaDetails = address[1] ? address[1] : "";
        landmark = address[2] ? address[2] : "";
      }
      return (
        <>
          <div>
            {checkAddress ? (
              <>
                <h6 className="">{address[0]}</h6>
                <h6 className="">{area}</h6>
                <h6 className="">
                  {landmark ? <>Landmark :{landmark}</> : ""}
                </h6>
              </>
            ) : (
              <h6 className="">{order?.address?.replaceAll("|", " ")}</h6>
            )}
          </div>
        </>
      );
    } else if (typeof order?.address === "object") {
      return (
        <>
          <div>
            <>
              <h6 className="">{order?.address?.displayAddress}</h6>
              {order?.userInfo?.room && (
                <h6 className="">Room No : {order?.userInfo?.room}</h6>
              )}

              <h6 className="">Landmark : {order?.address?.landmark}</h6>
            </>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg" centered>
        <ModalHeader toggle={toggle}>
          <div className="">
            <div>
              Order ID - #{order?.orderId}
              <div>
                <h6 className="mb-0 font-size-12 mt-2">
                  Created On :
                  <span className="mb-0 font-size-12 fw-bold">
                    {" "}
                    {order?.createdAt}
                  </span>
                </h6>
              </div>
            </div>
            <div>
              {order?.isHotel === true ? (
                <h5 className="mb-0 mt-2 text-danger">Hotel Order</h5>
              ) : (
                ""
              )}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="">
            <div className="d-flex justify-content-between align-items-center ">
              <div>
                <p className="mb-2">Appointment Date & Time :</p>
                <h6 className="">
                  {" "}
                  {order?.appointmentDate} @ {order?.appointmentTime}
                </h6>
              </div>
              <div>
                <button
                  className={
                    "btn btn-sm font-size-12 text-white " +
                    getStatusByBgClass(
                      " bg",
                      stylistnotavailable ? "Not available" : order?.status
                    )
                  }
                >
                  {stylistnotavailable
                    ? "Not available"
                    : order?.status === "cancel"
                    ? "Cancelled"
                    : order?.status === "Compelete"
                    ? "Completed"
                    : order?.status}
                </button>
              </div>
            </div>
            {/* )} */}
          </div>
          <CardBody className="mt-3">
            <div>
              <div className="">
                <div>
                  <div className="bg-light p-1 mb-3">
                    <p className="mb-0">Customer Details</p>
                  </div>
                  {order?.isHotel === true ? (
                    <div className="d-flex ">
                      {manager === true ? (
                        <>
                          <div className="d-flex me-3">
                            <div>
                              <i className="bx bxs-user-circle me-1 font-size-14"></i>
                            </div>
                            <h6 className="">
                              {order?.userInfo?.name
                                ? order.userInfo?.name
                                : "-"}{" "}
                              {/* {order?.user?.lastname ? order.user?.lastname : "-"} */}
                            </h6>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex me-3">
                            <div>
                              <i className="bx bxs-user-circle me-1 font-size-14"></i>
                            </div>
                            <h6 className="">
                              {order?.userInfo?.name
                                ? order.userInfo?.name
                                : "-"}{" "}
                              {/* {order?.user?.lastname ? order.user?.lastname : "-"} */}
                            </h6>
                          </div>
                          {order?.userInfo?.phone ? (
                            <div className="d-flex me-3">
                              <div>
                                <i className="bx bxs-phone-call me-1 font-size-14"></i>
                              </div>
                              <h6 className="">
                                {order?.userInfo?.phone
                                  ? order.userInfo?.phone
                                  : "-"}
                              </h6>
                            </div>
                          ) : (
                            ""
                          )}
                          {order?.userInfo?.email ? (
                            <div className="d-flex me-3">
                              <div>
                                <i className="bx bxs-envelope me-1 font-size-14"></i>
                              </div>
                              <h6 className="">
                                {order?.userInfo?.email
                                  ? order.userInfo?.email
                                  : "-"}
                              </h6>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="">
                      <div className="d-flex me-3">
                        <div>
                          <i className="bx bxs-user-circle me-1 font-size-14"></i>
                        </div>
                        <h6 className="">
                          {order?.user?.firstname ? order.user?.firstname : "-"}{" "}
                          {order?.user?.lastname ? order.user?.lastname : "-"}
                        </h6>
                      </div>

                      <div className="d-flex me-3">
                        <div>
                          <i className="bx bxs-phone-call me-1 font-size-14"></i>
                        </div>
                        <h6 className="">
                          {order?.user?.mobilenumber
                            ? order.user?.mobilenumber
                            : "-"}
                        </h6>
                      </div>
                      <div className="d-flex me-3">
                        <div>
                          <i className="bx bxs-envelope me-1 font-size-14"></i>
                        </div>
                        <h6 className="">
                          {order?.user?.email ? order.user?.email : "-"}
                        </h6>
                      </div>
                    </div>
                  )}

                  <div className="d-flex">
                    <div>
                      <i className="bx bxs-home me-1 font-size-14"></i>
                    </div>
                    {renderAddress()}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className=" border-3 mb-3">
                <div>
                  <div className="bg-light p-1">
                    <p className="mb-0">Stylist Details</p>
                  </div>
                  <div className="d-flex flex-wrap align-items-center mt-2">
                    {getSlotTimes(order.selectedList || []).map(
                      (stylist, indx) => {
                        return (
                          <div className="me-4 d-flex flex-wrap" key={indx}>
                            <ProfileImage
                              image={
                                stylist.stylistImage === "undefined"
                                  ? noImage
                                  : stylist.stylistImage
                              }
                              height="70"
                              width={70}
                              classNames="me-2 border"
                            />
                            <div>
                              <div className="d-flex align-items-center ">
                                <div>
                                  <i className="bx bxs-user-circle me-1 font-size-14"></i>
                                </div>
                                <h6 className="mt-1 ">
                                  {stylist.stylistName || "-"}{" "}
                                  {stylist.stylistShortName || "-"}
                                </h6>
                              </div>

                              <div className="d-flex align-items-center ">
                                <div>
                                  <i className="bx bxs-phone-call me-1 font-size-14"></i>
                                </div>
                                <h6 className="mt-1">
                                  {stylist.stylistMobile
                                    ? stylist.stylistMobile
                                    : "-"}
                                </h6>
                              </div>

                              <div className="d-flex">
                                <div>
                                  <i className="bx bxs-time me-1 font-size-14"></i>
                                </div>
                                <h6 className="">
                                  Service date & Time: <br />
                                  {stylist.slotTimes.map((slot, index) => (
                                    <h6 key={index} className="mt-1">
                                      {order?.appointmentDate} @ {slot}
                                      {index !== stylist.slots.length - 1 &&
                                        ", "}
                                    </h6>
                                  ))}
                                </h6>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody className="">
            <OrderDetailsTable
              serviceList={order.selectedList ? order.selectedList : []}
              activeOrder={order}
            />
          </CardBody>
          <Card className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="p-2 mt-2">Order Summary</h5>
              </div>
            </div>
            <CardBody className="border-top border-3 py-0 px-3">
              {" "}
              <div className="d-flex justify-content-start mb-0 mt-4 px-0">
                <div className="me-5">
                  {order?.status == "Paid" &&
                  order?.orderPaymentInfo?.paymentType ? (
                    <p className="">Payment Method </p>
                  ) : (
                    ""
                  )}
                  <p className="">Services Amount</p>
                  {/* <p className="">Products</p> */}
                  {order?.orderPaymentInfo?.additionalServiceAmount ? (
                    <p className="text-danger">Additional Charges</p>
                  ) : (
                    ""
                  )}

                  {order?.discountInfo ? (
                    <p className="text-success">Discount</p>
                  ) : (
                    ""
                  )}
                  <p className="">
                    <b>Grand Total</b>
                  </p>
                </div>

                <div className="text-end ms-5">
                  {order?.status == "Paid" &&
                  order?.orderPaymentInfo?.paymentType ? (
                    <p className="">{order?.orderPaymentInfo?.paymentType} </p>
                  ) : (
                    ""
                  )}
                  <p className="">
                    ₹ {order?.orderPaymentInfo?.serviceAmount?.toFixed(2)}
                  </p>
                  {order?.orderPaymentInfo?.additionalServiceAmount ? (
                    <p className="">
                      ₹{" "}
                      {order?.orderPaymentInfo?.additionalServiceAmount?.toFixed(
                        2
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                  {order?.discountInfo ? (
                    <p className="">
                      {order?.discountInfo?.discountType === "Amount"
                        ? "₹"
                        : ""}
                      {order?.discountInfo?.discountAmount}
                      {order?.discountInfo?.discountType === "Percentage"
                        ? "%"
                        : ""}
                    </p>
                  ) : (
                    ""
                  )}
                  {/* <p className="">₹ {0}</p> */}
                  <p className="">
                    ₹ {order?.orderPaymentInfo?.totalAmount?.toFixed(2)}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
          {/* <div className="d-flex justify-content-end">
            <button className="btn btn-sm mt-4 ms-2 btn-primary px-4 btn-primary-red">
              <i className="bx bx-download font-size-14 me-2"></i>
              Download
            </button>
          </div> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default StatementsOrderDetailsModal;
