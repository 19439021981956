import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";
import Axios from "axios";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    //  debugger;
    // alert(process.env.REACT_APP_DEFAULTAUTH)
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      //   alert(user)
      // Axios.post(`http://localhost:5000/api/v1/auth/login`,{email: 'devarapalli.gopi8@gmail.com', password: 'gopi123'})
      // .then(res=>{
      //     console.log(res.data);
      // })
      // debugger;
      console.log(user);
      const response = yield call(
        postJwtLogin,
        process.env.REACT_APP_DATABASEURL + "/api/v1/auth/login",
        { email: user.email, password: user.password }
      );
      console.log(response);
      //  localStorage.setItem("authUser", JSON.stringify(response));
      //  yield put(loginSuccess(response));
      if (response?.success) {
        localStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginSuccess(response));
        if (
          response?._doc?.role === "Admin" &&
          response?._doc?.adminRole === "super"
        ) {
          history.push("/dashboard");
        } else if (
          response?._doc?.role === "Support" &&
          response?._doc?.adminRole === "frontDesk"
        ) {
          history.push("/Provider-list-individul");
        } else if (
          response?._doc?.role === "Customer" &&
          response?._doc?.adminRole === "manager"
        ) {
          history.push("/Hotel_orders_statemets");
        } else if (
          response?._doc?.role === "Customer" &&
          response?._doc?.adminRole === "accountant"
        ) {
          history.push("/statements_list");
        } else {
          history.push("/dashboard");
        }
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, "/post-fake-login", {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    // history.push("/dashboard");
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
