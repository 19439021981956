import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import { getPaginatedOrders } from "services/orders.service";
import {
  getHotelOrdersByStatus,
  getOrderDetailsByOrderId,
  getOrdersByStatus,
  getOrdersCountsAndList,
  getOrdersCountsAndListHotel,
} from "services/V2_APIs/orders.service";
import OrdersListPage from "./ordersListPage";
import OrdersDetailspage from "./ordersDetailspage";
import { useHistory, useLocation } from "react-router-dom";
import HotelOrdeDetailsPage from "./HotelOrdeDetailsPage";
const MainList = ({ isHotel }) => {
  console.log(isHotel, "isHotel isHotel");
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [counts, setCounts] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [defaultTab, setDefaultTab] = useState("1");
  const [defaultOrders, setDefaultOrders] = useState([]);
  const [displayOrders, setDisplayOrders] = useState([]);
  const [activeOrder, setActiveOrder] = useState(null);
  const [completedStatusCount, setCompletedStatusCount] = useState(0);
  const [paidStatusCount, setPaidStatusCount] = useState(0);
  const [acceptedStatusCount, setAcceptedStatusCount] = useState(0);
  const [pendingStatusCount, setPendingStatusCount] = useState(0);
  const [cancelledStatusCount, setCancelledStatusCount] = useState(0);
  const [inProgressStatusCount, setInProgressStatusCount] = useState(0);
  const [notAvailableStatusCount, setNotAvailableStatusCount] = useState(0);
  const [todaysOrdersCount, setTodaysOrdersCount] = useState(0);
  const [allOrdersCount, setAllOrdersCount] = useState(0);
  const [pastPendingOrders, setPastPendingOrders] = useState(0);
  const [pastPendingOrdersTab, setPastPendingOrdersTab] = useState(false);
  console.log(pastPendingOrdersTab, "pstpendig");
  const [nextUrl, setNextUrl] = useState("");
  console.log(nextUrl, "nextUrlll");
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    getAllOrdersList();
  }, [isHotel]);

  useEffect(() => {
    FilterByStatus();
  }, [counts]);

  const getAllOrdersList = () => {
    setLoading(true);

    // Declare a promise variable to hold the appropriate function's result
    let ordersPromise;

    // Assign the promise based on the condition
    if (isHotel === true) {
      ordersPromise = getOrdersCountsAndListHotel();
    } else {
      ordersPromise = getOrdersCountsAndList();
    }

    // Handle the promise
    ordersPromise
      .then(res => {
        if (res?.data) {
          console.log(res?.data, "daaaa");
          if (res?.data?.counts?.length === 0) {
            setAcceptedStatusCount(0);
            setNotAvailableStatusCount(0);
            setPendingStatusCount(0);
            setInProgressStatusCount(0);
            setCompletedStatusCount(0);
            setPaidStatusCount(0);
            setCancelledStatusCount(0);
            setAllOrdersCount(0);
            setTodaysOrdersCount(0);
            setCounts([]);
            setDisplayOrders(
              res?.data?.orders?.length ? res?.data?.orders : []
            );
            setDefaultOrders(
              res?.data?.orders?.length ? res?.data?.orders : []
            );
          } else {
            setDisplayOrders(res?.data?.orders);
            setDefaultOrders(res?.data?.orders);
            setSearchInput("");
            setSearchValue("");
            setActiveTab("1");
            setDefaultTab("1");
            setCounts(res?.data?.counts ? res?.data?.counts : []);
            setTodaysOrdersCount(res?.meta?.count);
          }
          if (res?.data?.orders.length < 10) {
            setLoadMore(false);
          } else {
            setNextUrl(res?.meta?.nextUrl);
            setLoadMore(true);
          }
        }
        setLoading(false);
      })
      .catch(err => {
        console.error(err); // Log the error for debugging
        setLoading(false);
      });
  };

  const GetOrdersByTab = status => {
    setLoading(true);
    let getOrderByStatus;
    // Assign the promise based on the condition
    if (isHotel === true) {
      getOrderByStatus = getHotelOrdersByStatus(status);
    } else {
      getOrderByStatus = getOrdersByStatus(status);
    }
    // getOrdersByStatus(status)
    getOrderByStatus
      .then(res => {
        setDisplayOrders(res?.data);
        setDefaultOrders(res?.data);
        if (res?.data.length < 10) {
          setLoadMore(false);
        } else {
          setNextUrl(res?.meta?.nextUrl);
          setLoadMore(true);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  const getOrderDetailsByID = id => {
    setLoading(true);
    getOrderDetailsByOrderId(id)
      .then(res => {
        let resp = res?.data || [];
        setActiveOrder(resp);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  const handleActiveTab = (tab, status) => {
    setActiveTab(tab);
    setDefaultTab(tab);
    setSearchInput("");
    setSearchValue("");
    // history.push(`?status=${status}`);
    GetOrdersByTab(status);
    setActiveOrder(null);
    if (status === "pastPending") {
      setPastPendingOrdersTab(true);
    } else {
      setPastPendingOrdersTab(false);
    }
  };
  const handleActiveOrder = order => {
    getOrderDetailsByID(order._id);
    console.log(order, "order id");
  };

  const handleScroll = _.debounce(() => {
    setLoading(true);
    getPaginatedOrders(nextUrl)
      .then(res => {
        setDisplayOrders([...displayOrders, ...res?.data]);
        setDefaultOrders([...defaultOrders, ...res?.data]);
        if (res?.data.length < 10) {
          setLoadMore(false);
        } else {
          setNextUrl(res?.meta?.nextUrl);
          setLoadMore(true);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, 500);

  const FilterByStatus = () => {
    // debugger;
    if (counts?.length !== 0) {
      console.log(counts, "countsss");
      // Accept Status
      let AcceptedStatus = counts?.filter(ele => ele?._id === "Confirmed");

      let TotalAcceptedStatusCount = AcceptedStatus[0] // total count
        ? AcceptedStatus[0]?.statusCount
        : 0;

      let AcceptedStatusCount = AcceptedStatus[0] // order confirmed count
        ? AcceptedStatus[0]?.confirmedByStylistCount
        : 0;
      let NotAvailableStatusCount = AcceptedStatus[0] // stylist not available count
        ? AcceptedStatus[0]?.notAvailableCount
        : 0;
      let ConfirmedOrdersCount = AcceptedStatusCount - NotAvailableStatusCount;
      const totalNotAvailableCount = counts.reduce(
        (total, item) => total + item?.notAvailableCount,
        0
      );
      let PendingStatus = counts?.filter(ele => ele?._id === "Pending");
      let PendingStatusCount = PendingStatus[0]
        ? PendingStatus[0]?.statusCount
        : 0;
      let PastPendingOrdersCount = PendingStatus[0]
        ? PendingStatus[0]?.statusCount - PendingStatus[0]?.pastAppointmentDate
        : 0;
      // In Progress Status - Orders in progress

      let InprogressStatus = counts?.filter(ele => ele?._id === "In Progress" || ele?._id === "InProgress");

      let InProgressStatusCount = InprogressStatus[0]
        ? InprogressStatus[0]?.statusCount
        : 0;

      // Completed Status - Order Service Completed, but no payment yet
      let CompletedStatus = counts?.filter(ele => ele?._id === "Completed");
      let CompletedStatusCount = CompletedStatus[0]
        ? CompletedStatus[0]?.statusCount
        : 0;

      // Paid Status - Order officially completed with payment confirmed
      let PaidStatus = counts?.filter(ele => ele?._id === "Paid");
      let PaidStatusCount = PaidStatus[0] ? PaidStatus[0]?.statusCount : 0;

      // Cancelled Status - Order cancelled, mostly from user side
      let CancelledStatus = counts?.filter(
        ele => ele?._id === "cancel" || ele?._id === "Cancelled"
      );
      let CancelledStatusCount = CancelledStatus[0]
        ? CancelledStatus[0]?.statusCount
        : 0;

      // Total Order Count
      let allOrders =
        TotalAcceptedStatusCount +
        InProgressStatusCount +
        CompletedStatusCount +
        PaidStatusCount +
        CancelledStatusCount;

      setAcceptedStatusCount(ConfirmedOrdersCount);
      setNotAvailableStatusCount(totalNotAvailableCount);
      setPendingStatusCount(PendingStatusCount);
      setInProgressStatusCount(InProgressStatusCount);
      setCompletedStatusCount(CompletedStatusCount);
      setPaidStatusCount(PaidStatusCount);
      setCancelledStatusCount(CancelledStatusCount);
      setAllOrdersCount(allOrders);
      setPastPendingOrders(PastPendingOrdersCount);
    }
  };
  const statusClassMap = {
    1: "bg-today",
    2: "bg-pending",
    3: "bg-secondary",
    4: "bg-warning",
    5: "bg-success",
    6: "bg-info",
    7: "bg-danger",
    8: "bg-primary",
    9: "bg-red",
    10: "bg-past-pending",
  };
  const tabs = [
    { id: "1", title: "Today", subTitle: "today", count: todaysOrdersCount },
    {
      id: "2",
      title: "Pending",
      subTitle: "pending",
      count: pendingStatusCount,
    },
    {
      id: "3",
      title: "confirmed",
      subTitle: "confirmed",
      count: acceptedStatusCount,
    },
    {
      id: "4",
      title: "In Progress",
      subTitle: "inProgress",
      count: inProgressStatusCount,
    },
    {
      id: "5",
      title: "completed",
      subTitle: "completed",
      count: completedStatusCount,
    },
    { id: "6", title: "Paid", subTitle: "paid", count: paidStatusCount },
    {
      id: "7",
      title: "Cancelled",
      subTitle: "cancelled",
      count: cancelledStatusCount,
    },
    { id: "8", title: "All Orders", subTitle: "all", count: allOrdersCount },
    {
      id: "9",
      title: "Stylist not available",
      subTitle: "notAvailable",
      count: notAvailableStatusCount,
    },
    {
      id: "10",
      title: "Pending past orders",
      subTitle: "pastPending",
      count: pastPendingOrders,
    },
  ];
  return (
    <div>
      <div className="tabs order-dashboard">
        <Nav tabs>
          <NavItem className="d-flex justify-content-space-between flex-wrap">
            {tabs.map(tab => (
              <NavLink
                key={tab.id}
                className={
                  tab?.title === "Stylist not available"
                    ? "stylist_not_available"
                    : tab?.title === "Pending past orders"
                    ? "pending_past_orders"
                    : ""
                }
              >
                <CardBody
                  className={`shadow-sm rounded ${statusClassMap[tab.id]}`}
                  onClick={() => {
                    handleActiveTab(tab?.id, tab?.subTitle);
                  }}
                >
                  <div className="text-center">
                    <h6 className="pt-2">{tab?.title}</h6>
                    <h2>{tab?.count}</h2>
                    {activeTab === tab?.id && (
                      <div className="check_mark">
                        <i className="bx bx-check-circle"></i>
                      </div>
                    )}
                  </div>
                </CardBody>
              </NavLink>
            ))}
          </NavItem>
        </Nav>
      </div>
      <Row className="mt-3">
        <Col md="4">
          <Card>
            <CardBody className="p-2">
              <div className="search-box  position-relative w-100 ">
                <i className="bx bx-search-alt search-icon" />
                <input
                  className="form-control  rounded-pill bg-white "
                  type="search"
                  id="example-search-input"
                  placeholder=" Search..."
                  onChange={e => handleSearch(e.target.value)}
                />
              </div>
              {searchInput.length !== 0 ? (
                <span
                  role="button"
                  className="font-size-12 badge-soft-info py-1 px-2 me-1 fw-bold badge bg-bg-info search-input"
                >
                  <span className="font-size-12">Search: </span>
                  <span className="font-size-12 fw-bold text-dark">
                    <span className="ps-1 text-dark pt-1">{searchInput}</span>
                  </span>
                </span>
              ) : (
                ""
              )}
            </CardBody>
            <OrdersListPage
              handleActiveOrder={handleActiveOrder}
              handleScroll={handleScroll}
              displayOrders={displayOrders}
              loadMore={loadMore}
              activeOrder={activeOrder}
              getallOrders={getAllOrdersList}
              isHotel={isHotel}
              reload={handleActiveOrder}
              pastPendingTab={pastPendingOrdersTab}
            />
          </Card>
        </Col>

        {isHotel === true ? (
          <Col md="8" className="Order_details_page">
            <HotelOrdeDetailsPage
              activeOrder={activeOrder}
              reload={handleActiveOrder}
              getallOrders={getAllOrdersList}
              isHotel={isHotel}
              pastPendingTab={pastPendingOrdersTab}
            />
          </Col>
        ) : (
          <Col md="8" className="Order_details_page">
            <OrdersDetailspage
              activeOrder={activeOrder}
              reload={handleActiveOrder}
              getallOrders={getAllOrdersList}
              pastPendingTab={pastPendingOrdersTab}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default MainList;
